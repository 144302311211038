﻿header {
    @apply shadow-md;
}

.speed-header-container {
    @apply container mx-auto px-2.5 bg-white;
}

.header-top-stripe {
    @apply flex float-none text-sm text-right border-b border-gray-300;

    ul {
        @apply w-full mt-1 flex text-left overflow-x-auto overflow-y-hidden;

        li {
            @apply py-1 leading-6 border-b-2 border-transparent;

            a {
                @apply px-3.5 text-primary-600 leading-[1.75rem];
            }
        }
    }
}

.header-lang {
    @apply flex items-center ml-auto pl-7;

    a {
        @apply pl-3.5 text-primary-600 leading-[1.75rem];
    }
}

.header-content {
    @apply h-[6.25rem] hidden md:flex flex-row flex-nowrap justify-between items-center;

    .logo {
        background-size: 3.875rem;
        @apply align-middle h-[5.5rem] mr-auto;

        img {
            @apply h-full;
        }
    }

    .menucontent {
        @apply flex gap-3;

        a {
            @apply px-2.5 text-base font-bold;
        }
    }
}

#tablet-header {
    @apply block md:hidden w-full h-[4.5rem] px-4 pt-4 leading-[2.5rem] flex flex-wrap justify-between;

    .logo img {
        @apply h-[3.5rem];
    }

    .mobile-menu-button {
        @apply flex gap-3 items-center text-[1.0625rem];
    }
}

.login-button-wrapper {
    @apply ml-8;

    a {
        @apply items-center gap-1;
    }

    a.btn {
        @apply hidden md:flex;
    }

    a:not(.btn) {
        @apply flex md:hidden;
    }
}
