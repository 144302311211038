﻿.form-field--select {
    @apply relative my-2;
    @apply text-sm;

    [aria-expanded="true"] {
        &[type=button] svg {
            @apply rotate-180;
        }

        [role=search] input {
            @apply visible;
        }
    }

    .no-label[type=button] {
        @apply py-1.5;
    }

    [type=button]:not(.no-label) {
        @apply pt-3 pb-0;
    }

    [type=button] {
        @apply block w-full px-3 m-0;
        @apply text-base font-normal text-left;
        @apply text-gray-700 bg-white bg-clip-padding hover:bg-gray-50 focus:text-gray-700 focus:bg-gray-100;
        @apply border border-0 border-b border-solid border-gray-300 focus:border-primary-400;
        @apply transition ease-in-out;
        @apply focus:shadow-primary-400;

        svg {
            @apply top-1/2 absolute -translate-y-1/2;
            @apply right-2 h-5 w-5;
            @apply rotate-0 transition-all;
        }

        &:focus-within ~ label {
            @apply text-primary-400;
        }
    }

    > label {
        @apply absolute top-0 left-0 h-full px-3 py-2.5;
        @apply text-gray-700 leading-4 text-sm;
        @apply transition-all pointer-events-none;
        @apply leading-3 -translate-y-2;
        font-size: 0.7rem;
    }

    [role=search] {
        @apply relative p-0.5 pb-1;

        input {
            @apply invisible w-full px-3 py-1;
            @apply text-sm font-normal;
            @apply text-gray-700 bg-gray-50 bg-clip-padding hover:bg-white focus:text-gray-700 focus:bg-white;
            @apply border border-solid border-gray-300 rounded focus:border-primary-400 focus:outline-none;
            @apply transition ease-in-out;
            @apply focus:shadow-primary-400;

            &:focus {
                @apply shadow-primary-400;
            }
        }

        i {
            @apply mx-2 my-0;
            @apply text-gray-400;
            @apply top-1/2 absolute -translate-y-1/2;
        }

        > i ~ input {
            @apply pl-7;
        }
    }

    [role=menu] {
        @apply origin-top-right absolute right-0 mt-0.5 shadow-lg bg-white ring-1 ring-black ring-opacity-5;
        @apply overflow-hidden z-10 shadow-none opacity-0 scale-75 transition-all;
        box-shadow: none;

        &[aria-expanded="true"] {
            @apply shadow opacity-100 scale-100;

            &:not(.transitioning) ul {
                @apply overflow-auto;
            }
        }

        li {
            @apply block px-4 py-2;
            @apply text-gray-700 text-sm;
            @apply hover:cursor-pointer hover:text-primary-500 hover:bg-gray-50;

            &[aria-selected="true"] {
                @apply text-primary-400 bg-gray-100;
            }

            &.focus {
                @apply text-primary-600 bg-gray-200;
            }
        }
    }
}
