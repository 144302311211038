﻿.sale-cards {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8;
}

.sale-card {
    @apply bg-white shadow-lg border border-gray-50 rounded;
}

.sale-card--status {
    @apply w-full p-1 text-white text-center uppercase bg-primary border border-primary rounded-t relative;
}

.sale-card--status-envelope {
    @apply absolute top-4 right-4 px-0.5 bg-white border border-white rounded;

    i {
        @apply -mt-0.5 text-green-500;
    }

    > div {
        @apply absolute bottom-[-0.25rem] right-[-0.25rem] w-5 h-5 text-sm font-bold bg-red-500 rounded-full;
    }
}
.sale-card--body {
    @apply min-h-[12rem] p-4 flex flex-col text-center;

    h3 {
        @apply m-0 text-3xl text-secondary-600;
    }

    a {
        @apply hover:text-primary-700 transition-colors;
    }
}

.sale-card--footer {
    @apply pb-12 flex;

    a {
        @apply mx-auto px-5 py-1.5 text-primary hover:text-primary-400 border-2 border-primary hover:border-primary-400 rounded-full transition-colors;
    }
}