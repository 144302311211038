﻿.content-command-bar {
    @apply text-sm bg-gray-50 w-full h-12 -mr-4 mb-2 px-4 md:-mx-6 md:w-[calc(100%+3rem)];
    @apply flex flex-row content-center items-center overflow-hidden;

    button,
    [role=button] {
        @apply mr-1 px-3 py-1;
        @apply text-primary-400 bg-gray-50 hover:bg-gray-100 hover:border-gray-300 active:bg-gray-200;
        @apply border border-transparent rounded transition-colors;
        @apply uppercase;
        @apply flex-row flex-nowrap content-center items-center gap-x-2;
        @apply disabled:text-gray-400 disabled:bg-gray-50 disabled:border-transparent;

        &:not(.hidden) {
            @apply flex;
        }

        i {
            @apply text-lg;
        }
    }

    [role=menu] {
        @apply absolute bg-transparent opacity-0 scale-75;
        @apply transition-all duration-300;
        width: inherit;
        z-index: 5;

        &[aria-expanded=true] {
            @apply opacity-100 scale-100;
        }

        &.transitioning, &[aria-expanded=false] {
            @apply overflow-hidden;
        }

        [role=list] {
            @apply mr-1 my-0.5 py-0.5 bg-white border rounded shadow;
        }

        button {
            @apply w-full justify-start text-left bg-white border-0 hover:text-primary-500 hover:bg-gray-50;
        }
    }

    .form-field input {
        @apply text-gray-700 bg-gray-50 hover:bg-white focus:text-gray-700 focus:bg-white;
        @apply border border-solid border-gray-300 rounded;
    }

    [role="button"] {
        @apply relative;

        &::after {
            content: '';
            @apply block absolute top-2 right-2 w-5 h-5 ml-auto shrink-0 transform bg-no-repeat rotate-180;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-size: 1.25rem;
            transition: transform 0.2s ease-in-out;
        }

        &:not([aria-expanded="true"])::after {
            @apply rotate-0;
            content: '';
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
    }
}
