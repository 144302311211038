﻿footer {
    @apply w-full flex flex-col;

    > * {
        @apply w-full px-2.5 py-5 border-t border-gray-300;

        > * {
            @apply container mx-auto px-2.5 flex;
        }
    }

    a {
        @apply underline text-primary-600 hover:text-primary-800 transition-colors;
    }

    ul {
        @apply md:mx-auto md:flex gap-5 text-sm;
    }
}

.footer--top-bar > * {
    @apply py-2 container flex flex-wrap gap-2 place-content-between;
}

.footer--top-bar--title,
.footer--bottom-bar {
    @apply w-full md:w-auto text-lg;
}

.footer--top-bar--title {
    @apply font-bold;
}
