﻿.top-bar {
    @apply w-full bg-gray-100 text-primary-700 text-sm border-t border-gray-200 shadow-md;

    > * {
        @apply container mx-auto my-2 md:my-0 flex flex-col md:flex-row md:gap-3 place-content-end;

        > * {
            @apply px-3 py-1 md:py-4 relative;

            &:not(:last-child):after {
                content: '';
                @apply absolute top-2 -right-1.5 h-8 hidden md:block border-l;
            }
        }
    }
}
