﻿.left-menu {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    @apply min-w-[11rem] px-5 py-4 mr-12 bg-white rounded;

    h3 {
        @apply mb-0 text-lg uppercase;
    }

    div {
        @apply py-[0.1875rem];
    }

    a {
        @apply text-primary-400 hover:text-primary-300 transition-colors;

        &.active {
            @apply text-primary-700 hover:text-primary-700;
        }
    }

    hr {
        @apply my-5;
    }
}