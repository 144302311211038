﻿.snackbar {
    @apply fixed z-30;
    @apply bottom-4 left-1/2 -translate-x-1/2 pl-4 pr-10 py-3;
    @apply bg-gray-100 border border-gray-400 text-gray-700 rounded;
    @apply opacity-0 scale-75 shadow-none shadow-primary-400 transition-all;
    min-width: 20rem;

    &.closed,
    &#blazor-error-ui:not([style*="display: block"]),
    &#blazor-error-connection:not([style*="display: block"]) {
        @apply invisible;
        z-index: -1;
    }

    &.open,
    &[style*="display: block"] {
        @apply opacity-100 scale-100 drop-shadow-md;
    }

    .reload {
        @apply underline cursor-pointer text-gray-500 hover:text-primary-400 transition-colors;
    }

    .snackbar--btn-close,
    .dismiss {
        @apply absolute top-1 right-2;
        @apply text-gray-300 cursor-pointer;
        @apply transition-all scale-100 hover:text-gray-500 hover:scale-110;
    }
}
