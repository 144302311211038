﻿.loading > * {
    @apply hidden;
}

@keyframes fadein {
    from {
        @apply opacity-0;
    }

    to {
        @apply opacity-100;
    }
}

header.loading {
    @apply block mt-4 mx-4 md:mx-0 w-full h-16; /* change height to see repeat-y behavior */
    background-image: linear-gradient( 100deg, RGBA(255, 255, 255, 0), RGBA(255, 255, 255, 0.5) 50%, RGBA(255, 255, 255, 0) 80% ), linear-gradient( lightgray 1rem, transparent 0 ), linear-gradient( lightgray 1.75rem, transparent 0 );
    background-size: 4.5rem 200px, /* highlight */
    18rem 200px, 14rem 200px;
    background-position: 0 0, /* highlight */
    0 0, 0 1.5rem;
    background-repeat: repeat-y;
    animation: heading__shine 1s infinite, fadein 0.25s;
}

@keyframes heading__shine {
    to {
        background-position: 100% 0, /* move highlight to right */
        0 0, 0 1.5rem;
    }
}

.loading.data-items {
    @apply block mt-4 w-full h-80; /* change height to see repeat-y behavior */
    background-image: linear-gradient( 100deg, RGBA(255, 255, 255, 0), RGBA(255, 255, 255, 0.5) 50%, RGBA(255, 255, 255, 0) 80% ), linear-gradient( lightgray 3rem, transparent 0 ), linear-gradient( lightgray 1.5rem, transparent 0 ), linear-gradient( lightgray 2rem, transparent 0 ), linear-gradient( lightgray 2rem, transparent 0 ), linear-gradient( lightgray 2rem, transparent 0 ), linear-gradient( lightgray 2rem, transparent 0 );
    background-size: 4.5rem 20rem, /* highlight */
    100vw 20rem, 14rem 20rem, 80% 20rem, 80% 20rem, 80% 20rem, 80% 20rem;
    background-position: 0 0, /* highlight */
    0 0, 0 4rem, 0 6.5rem, 0 9rem, 0 11.5rem, 0 14rem;
    background-repeat: repeat-y;
    animation: data-items__shine 1s infinite, fadein 0.25s;

    .pagination {
        @apply hidden;
    }
}

@keyframes data-items__shine {
    to {
        background-position: 100% 0, /* move highlight to right */
        0 0, 0 4rem, 0 6.5rem, 0 9rem, 0 11.5rem, 0 14rem;
    }
}
