﻿.tooltip {
    z-index: 2;

    [type="button"] {
        @apply relative;
    }

    [role="tooltip"] {
        @apply inline-block absolute py-2 px-3;
        @apply font-sans font-medium text-xs text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 dark:bg-gray-700;

        &:not(.show) {
            @apply invisible opacity-0;
        }

        .tooltip-arrow {
            @apply absolute;
        }
    }
}

.tooltip-top {
    [role="tooltip"] {
        @apply left-1/2 -translate-x-1/2;
        bottom: calc(100% + 0.5rem);

        .tooltip-arrow {
            @apply -bottom-2 left-1/2 -translate-x-1/2;
            @apply border-t-8 border-x-8 border-x-transparent border-t-gray-900;
        }
    }
}

.tooltip-right {
    [role="tooltip"] {
        @apply top-1/2 -translate-y-1/2;
        right: calc(100% + 0.5rem);

        .tooltip-arrow {
            @apply -right-2 top-1/2 -translate-y-1/2;
            @apply border-l-8 border-y-8 border-y-transparent border-l-gray-900;
        }
    }
}

.tooltip-left {
    [role="tooltip"] {
        @apply top-1/2 -translate-y-1/2;
        left: calc(100% + 0.5rem);

        .tooltip-arrow {
            @apply -mt-2 -left-2 top-1/2 translate-y-1/2;
            @apply border-l-8 border-y-8 border-y-transparent border-l-gray-900;
            transform: scaleX(-1);
        }
    }
}
