﻿#mp-pusher {
    //transform: translate3d(240px, 0px, 0px);
}

nav {
    @apply absolute top-0 left-0 z-10 w-60 h-full bg-gray-100;

    &.open {
        transform: translate3d(-100%, 0, 0);
    }
}

.navbar {
    @apply h-10 md:h-auto flex items-center flex-wrap md:flex-nowrap md:justify-items-start;
    @apply bg-white md:shadow-md;
    @apply w-full mx-auto;
    @apply flex items-center justify-between;
    @apply print:hidden;

    .navbar-brand {
        @apply ml-2 md:ml-6;

        img {
            @apply w-auto h-10;
        }
    }

    .navbar-title {
        @apply min-w-0 flex-1 grow md:hidden mr-4 ml-1 overflow-hidden;
        @apply text-secondary;

        h1 {
            @apply text-lg mb-0 truncate whitespace-nowrap;
        }
    }

    .navbar-level1[role="menu"] {
        @apply items-center basis-full grow;
        @apply md:flex md:justify-end md:basis-auto md:h-full;
        @apply text-base font-sans text-gray-700 bg-white;
        @apply transition-all duration-300;
        @apply shadow-none overflow-hidden md:overflow-visible;
        z-index: 5;

        &[aria-expanded="true"] {
            @apply shadow md:shadow-none;
        }

        > *[role="list"] > ul {
            @apply ml-auto;
            @apply flex flex-col md:flex-row;

            li {
                .nav-link {
                    @apply block px-4 py-2 md:p-6 cursor-pointer hover:text-secondary-500 transition-colors;

                    &.active {
                        @apply text-secondary-500 font-normal;
                    }
                }

                > .nav-link {
                    @apply md:m-6 md:p-0 relative;

                    &:after {
                        @apply md:content-[''] absolute left-0 -bottom-1 w-0 h-0.5 bg-secondary-500 transition-all duration-300;
                    }

                    &:hover:after {
                        @apply w-full;
                    }
                }

                &.expander {
                    @apply relative;

                    .nav-link.expander-toggle {
                        @apply md:hidden;
                    }

                    .nav-link:not(.expander-toggle) {
                        @apply hidden md:block py-5;
                    }
                }
            }
        }
    }

    .expander [role=button] {
        .nav-link.expander-toggle::after {
            content: '';
            @apply block md:hidden absolute top-2 right-2 w-5 h-5 ml-auto shrink-0 transform bg-no-repeat rotate-180;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-size: 1.25rem;
            transition: transform 0.2s ease-in-out;
        }

        &:not([aria-expanded="true"]) .nav-link.expander-toggle::after {
            @apply rotate-0;
            content: '';
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
    }

    .navbar-level2[role="menu"] {
        @apply static md:absolute right-4 md:-mt-6;
        @apply bg-white border-0 md:border;
        @apply transition-all duration-300;
        @apply shadow-none opacity-0 md:scale-75;
        box-shadow: none;
        z-index: 5;

        &[aria-expanded="true"] {
            @apply shadow opacity-100 md:scale-100;
        }

        &.transitioning {
            @apply overflow-hidden;
        }

        .nav-link--user-panel {
            @apply w-full block whitespace-nowrap px-4 py-2 text-sm md:p-3 cursor-pointer hover:text-secondary-500 hover:bg-gray-50 transition-colors;
        }

        .nav-link-divider {
            @apply border-t my-1;
        }
    }
}

/* Icon animation source: https://mdbootstrap.com/snippets/jquery/mdbootstrap/911054 */
.navbar-toggler {
    @apply relative p-0;

    .navbar-toggler--icon {
        @apply relative w-5 h-4 cursor-pointer mx-2;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }

    .navbar-toggler--icon span {
        @apply block absolute w-full left-0 bg-white;
        height: 3px;
        border-radius: 9px;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 7px;
        }

        &:nth-child(3) {
            top: 14px;
        }
    }

    &[aria-expanded="true"] .navbar-toggler--icon span {

        &:nth-child(1) {
            top: 7px;
            transform: rotate(135deg);
        }

        &:nth-child(2) {
            opacity: 0;
            left: -60px;
        }

        &:nth-child(3) {
            top: 7px;
            transform: rotate(-135deg);
        }
    }
}
