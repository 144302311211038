﻿@tailwind base;

@tailwind components;
@import './components/action-panel.scss';
@import './components/blazor-table.scss';
@import './components/breadcrumb.scss';
@import './components/button.scss';
@import './components/card.scss';
@import './components/cookie-management.scss';
@import './components/command-bar.scss';
@import './components/footer.scss';
@import './components/form.scss';
@import './components/header.scss';
@import './components/left-menu.scss';
@import './components/loading.scss';
@import './components/menu-panel.scss';
@import './components/navbar.scss';
@import './components/page-document.scss';
@import './components/sale-card.scss';
@import './components/select.scss';
@import './components/snackbar.scss';
@import './components/table.scss';
@import './components/tabs.scss';
@import './components/tooltip.scss';
@import './components/top-bar.scss';

@tailwind utilities;

/* TODO Fix the building problem of layer component */
/*@layer components {
    .layer-error-box {
        @apply text-sm text-center text-red-600 bg-red-50 border border-red-400;
    }
}*/

html {
    font-family: "MuseoSans", Arial, Helvetica, sans-serif;
    letter-spacing: -0.01em;
    line-height: 1.5em;
    @apply min-w-[24rem];
    -webkit-print-color-adjust: exact;
}

header {
    @apply hidden md:block;
}

body {
    @apply text-secondary;
    @apply relative min-h-screen h-max;

    &:before {
        content: '';
        @apply hidden md:block absolute top-0 left-0 w-full h-full bg-white;
        //background-image: linear-gradient(340deg, #0f2447 0%, #6392dd 100%);
        z-index: -1;
    }
}

#app > .content:not(.hidden) {
    @apply min-h-screen flex flex-col;
}

* {
    @apply focus-visible:outline-gray-300;
}

.main-wrapper {
    @apply flex flex-grow items-start container mx-auto my-6 px-2.5;
}

main {
    @apply flex-grow;
}

.page-header {
    @apply hidden md:block;
}

h1, h2, h3, h4, h5, h6 {
    /*font-stretch: condensed;*/
    @apply font-headings font-semibold leading-tight text-secondary;
}

h1 {
    @apply text-4xl mt-0 mb-2;
}

h2 {
    @apply text-3xl mt-0 mb-2;
}

h3 {
    @apply text-2xl mt-0 mb-2;
}

h4 {
    @apply text-xl mt-0 mb-2;
}

main a {
    @apply text-primary-500 underline hover:text-primary-300 focus:text-primary-300 active:text-primary-200 transition-colors;
}

pre {
    @apply overflow-auto;
}

.text-2xs {
    font-size: 0.625rem;
    line-height: 0.875rem;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}

.error-wrapper,
.login-wrapper,
.status-wrapper,
.overlay {
    @apply w-full h-screen md:w-[40rem] xl:w-[52rem] px-6 md:px-[unset] pb-32 mx-auto text-center flex-col place-content-center;

    &.active {
        @apply w-full fixed z-20 flex;
    }

    img {
        @apply h-36 mx-auto mb-4;
    }

    h1 {
        @apply text-gray-700 mb-6 text-2xl;
    }
}

.error-wrapper.active {
    @apply bg-white;

    p {
        @apply overflow-y-auto;
    }
}

.overlay.active {
    background-color: rgba(256,256,256,0.8);
}

.offline-indicator {
    @apply py-1 /*layer-error-box*/;
    @apply text-sm text-center text-red-600 bg-red-50 border border-red-400;
}

.error-box {
    /*@apply layer-error-box;*/
    @apply text-sm text-center text-red-600 bg-red-50 border border-red-400;
}

.rounded-pill {
    @apply rounded-l-full rounded-r-full;
}

.alert {
    @apply px-4 py-3 border rounded;
}

.alert-success {
    @apply text-lime-800 border-lime-800 bg-lime-50;
}

.alert-warning {
    @apply text-amber-700 border-amber-700 bg-amber-100;
}

.alert-danger {
    @apply text-red-700 border-red-700 bg-red-100;
}

.d-flex {
    @apply flex;
}

.justify-content-end {
    @apply justify-end;
}

dt {
    @apply text-sm float-left;
}

dd {
    @apply text-sm float-right font-semibold;
}

.page--index {
    @apply grid grid-cols-2 md:grid-cols-3 container mx-auto my-4 px-4;
    max-width: 1024px;

    > * {
        @apply text-xl font-semibold text-primary-500 bg-white transition-all;
        @apply border rounded shadow;
        @apply hover:bg-gray-50 hover:shadow-lg;
        @apply active:bg-gray-100 active:scale-95 active:shadow-xl;
    }

    .btn {
        @apply h-32 md:h-56 flex content-center justify-center;
        background: linear-gradient(-45deg, rgba(0,0,0,0.03), rgba(255,255,255,0.09));

        i {
            @apply text-4xl mb-3 md:text-7xl md:mb-6;
        }
    }

    h1 {
        @apply grow text-primary-500 text-xl font-normal text-center px-4;
    }
}

.page--index-blank {
    @apply mx-4 md:mx-auto mt-4;

    h1 {
        @apply text-2xl md:text-4xl;
    }
}

.page-section {
    @apply mb-2;

    *[role="button"] > h2 {
        @apply ml-8 md:ml-7 mb-0;
    }

    h2 {
        @apply mt-4 ml-3 mb-3 pb-1 text-sm text-primary-500;
    }

    [role="button"] {
        @apply relative;

        &::before {
            content: '';
            @apply block absolute top-0.5 left-3 md:left-2 w-4 h-4 shrink-0 transform bg-no-repeat rotate-180;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232354a3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-size: 1rem;
            transition: transform 0.2s ease-in-out;
        }

        &:not([aria-expanded="true"])::before {
            @apply rotate-0;
            content: '';
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232354a3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
    }

    [role="menu"] {
        @apply transition-all duration-300;

        &.transitioning {
            @apply overflow-hidden;
        }
    }
}

h1 .fa-spin-pulse.fa-lg {
    font-size: 2rem;
    line-height: 2rem;
}

.fa-spin-pulse.fa-2x {
    font-size: 2rem;
}

.loading-progress {
    @apply block w-32 h-32 mx-auto;

    circle {
        fill: none;
        @apply stroke-gray-100 stroke-[0.6rem];
        transform-origin: 50% 50%;
        transform: rotate(-90deg);

        &:last-child {
            @apply stroke-primary-500;
            stroke-dasharray: calc(3.141 * var(--blazor-load-percentage, 0%) * 0.8), 500%;
            transition: stroke-dasharray 0.05s ease-in-out;
        }
    }
}

.loading-progress-text {
    @apply text-center font-bold;
    margin-top: -4.75rem; 

    &:after {
        content: var(--blazor-load-percentage-text, "Loading");
    }
}

.interim-row {
    @apply py-1 flex gap-2 min-h-[2.5rem];
}

.interim-row--section {
    @apply min-w-[4rem] my-auto;
}

.interim-row--name {
    @apply my-auto grow;
}

.interim-row--rownr {
    @apply min-w-[2rem] my-auto text-center;
}

.interim-row--value {
    @apply min-w-[8rem] max-w-[8rem] my-auto text-right;

    input {
        @apply w-full p-1 text-right;
    }

    :not(input) {
        @apply p-1;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
