﻿table {
    @apply text-sm;
}

.table-responsive {
    @apply overflow-auto;
}

.table {
    thead th {
        @apply p-2 text-left font-bold;
    }

    thead {
        @apply border-b;
    }

    td {
        @apply p-2;

        button {
            @apply -my-1 py-1;
        }
    }
}

.table.blazor-table.table--financial {
    font-stretch: condensed;
    @apply mb-8 w-full;

    thead {
        tr {
            @apply text-white bg-secondary;
        }

        th {
            @apply py-0.5 text-xl font-bold text-left;
        }

        td {
            @apply py-0.5 text-xl font-bold text-right;
        }
    }

    tbody {
        th {
            @apply py-0.5 text-left text-base text-gray-400;
        }

        td {
            @apply py-0.5 text-right text-base text-gray-400;
        }

        .row-summary {
            @apply text-lg font-bold text-gray-800 bg-gray-200;
        }

        .row-highlight {
            @apply text-lg font-bold text-white bg-primary;
        }

        .row-bold {
            @apply font-bold text-gray-800;
        }

        .row-italic {
            @apply italic;
        }
    }
}