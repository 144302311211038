﻿.action-panel {
    @apply fixed h-full w-full right-0 top-0 z-10;

    .action-panel--overlay {
        @apply fixed top-0 right-0 w-full h-full bg-black overflow-hidden;
        @apply opacity-0 transition-opacity duration-300;
    }

    &.open {
        .action-panel--overlay {
            @apply opacity-20;
        }

        aside {
            @apply translate-x-0 shadow-2xl;
        }
    }

    &.processing {
        aside {
            @apply pointer-events-none select-none;
        }

        .action-panel__command-bar,
        .action-panel__content:after {
            @apply opacity-40;
        }

        .action-panel__content__processing {
            @apply opacity-100;
        }
    }

    &:not(.processing) {
        .action-panel__content:after,
        .action-panel__content__processing {
            z-index: -1;
        }
    }

    aside {
        @apply absolute overflow-hidden top-0 right-0 w-full sm:w-auto h-full z-20;
        @apply flex flex-col bg-white shadow-none;
        @apply transition-all duration-300;
        @apply translate-x-full;

        .action-panel__command-bar {
            @apply w-full flex h-11 md:h-16 pl-4 md:py-2.5 transition-opacity duration-300;
            @apply bg-gray-50;
            min-height: 2.5rem;

            @media (min-width: 768px) {
                min-height: 4rem;
            }

            button {
                @apply px-2.5 py-1.5;
            }

            .action-panel__command-bar__close {
                @apply w-11 ml-auto;
                @apply text-primary-400 bg-transparent hover:bg-gray-100 active:bg-gray-200;
            }
        }

        h3 {
            @apply mb-4;
        }

        .action-panel__content {
            @apply relative w-full grow overflow-auto p-3 pt-4 md:p-4 md:pt-6;

            &:after {
                @apply content-[''] absolute w-full h-full top-0 left-0 bg-white opacity-0 transition-all duration-300;
            }
        }

        .action-panel__content__processing {
            @apply absolute w-full h-full top-0 flex items-center justify-center text-primary-400;
            @apply opacity-0 transition-opacity duration-300;
        }
    }
}
