﻿.tabs {
    @apply text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700;

    ul {
        @apply flex flex-wrap -mb-px;
    }

    li {
        @apply mr-2;

        button {
            @apply inline-block px-4 py-3 border-0 border-gray-200 text-secondary font-bold hover:text-secondary-700;

            &.active {
                @apply border border-gray-200 border-b-sky-50 bg-sky-50;
            }

            &.disabled {
                @apply text-gray-400 cursor-not-allowed;
            }

            i {
                @apply p-0;
            }
        }
    }
}
