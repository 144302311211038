﻿.page--document {
    @apply px-4 md:px-0 pt-5 md:pt-0;

    h2 {
        @apply pt-5 pb-2;
    }

    h3 {
        @apply pt-3 pb-1;
    }

    p {
        @apply py-1.5;
    }

    ul {
        list-style-type: circle;
        list-style-position: inside;
        @apply px-5 pb-2;
    }

    li {
        @apply py-1;
    }
}
