﻿.table.blazor-table {
    width: auto;

    .badge {
        font-size: 1em;
        font-weight: normal;
    }

    .material-icons {
        font-size: 1rem;
    }

    .thead-light tr {
        background-color: transparent;
        @apply border-b-2 border-gray-100;
    }

    .blazor-table__active-row {
        @apply bg-secondary-500/10;
    }

    &[aria-readonly="false"] td {
        @apply py-1;
    }

    th {
        font-weight: normal;
        font-size: 0.8125rem;
        padding: 0.5rem;
        white-space: nowrap;

        .column-expander:hover, .column-checkbox:hover {
            cursor: default;
        }

        > div {
            display: flex;

            > *:nth-child(2) {
                padding-left: 0.75rem;
                min-width: 1.5rem;
            }

            > div > a {
                padding-right: .5rem;
            }

            > span:first-of-type {
                margin-right: auto;
            }
        }

        img {
            height: 0.625rem;
        }
    }

    tr {
        @apply border-b border-gray-100;
    }

    td {
        @apply p-2;

        button {
            @apply -my-1 py-1;
        }
    }

    td.column-expander, th.column-expander {
        width: 100%;
        padding: 0;
    }

    td.column__checkbox {
        padding: 0;
        height: 36px;
    }

    td.column__checkbox .mat-checkbox.mdc-form-field {
        height: 36px;

        .mdc-checkbox {
            height: 14px;
            margin-top: -4px;
        }
    }

    .blazor-table__no-data {
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }
}

.data-items .pagination .page-link {
    @apply text-inherit no-underline;
}

.data-items--fixed {
    @apply relative;

    .data-items--filter-row {
        @apply absolute top-9;
        @apply text-sm bg-gray-50 w-full h-12 -mr-4 mb-2 px-6 md:-mx-6 md:w-[calc(100%+3rem)];
        @apply flex flex-row flex-nowrap content-center items-center gap-x-2;
    }

    table.table.blazor-table {
        table-layout: fixed;
        @apply w-full;

        th:last-child,
        td:last-child:not(:first-child) {
            @apply block;
        }

        tbody:before {
            content: "-";
            display: block;
            line-height: 3rem;
            color: transparent;
        }
    }
}

.pagination {
    @apply flex mt-4 text-sm border text-gray-600 rounded;

    .page-item {
        @apply border flex place-content-center bg-gray-50 pointer-events-none transition-colors;

        &:not(.disabled) {
            @apply pointer-events-auto bg-white hover:text-primary-600;
        }

        > * {
            @apply block px-2 py-1;
        }
    }
}
