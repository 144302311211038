﻿.menu-panel {
    --menu-panel-width: 15rem;
    --menu-panel-left: calc(-1 * var(--menu-panel-width));
    --menu-panel-translate-x: calc(2 * var(--menu-panel-width));
    @apply fixed h-full w-[var(--menu-panel-width)] left-[var(--menu-panel-left)] top-0 z-10;

    .menu-panel--overlay {
        @apply fixed top-0 left-0 w-full h-full bg-black overflow-hidden;
        @apply opacity-0 transition-opacity duration-300;
    }

    &.open {
        .menu-panel--overlay {
            @apply opacity-20;
        }

        aside {
            @apply translate-x-[var(--menu-panel-translate-x)] shadow-2xl;
        }
    }

    &.processing {
        aside {
            @apply pointer-events-none select-none;
        }

        .menu-panel__content:after {
            @apply opacity-40;
        }

        .menu-panel__content__processing {
            @apply opacity-100;
        }
    }

    &:not(.processing) {
        .menu-panel__content:after,
        .menu-panel__content__processing {
            z-index: -1;
        }
    }

    aside {
        @apply absolute overflow-hidden top-0 left-[var(--menu-panel-left)] w-full sm:w-auto h-full z-20;
        @apply flex flex-col bg-white shadow-none;
        @apply transition-all duration-300;
        @apply translate-x-full;

        h3 {
            @apply mb-4;
        }

        .menu-panel__content {
            @apply relative w-full grow overflow-auto flex flex-col;

            &:after {
                @apply content-[''] absolute w-full h-full top-0 left-0 bg-white opacity-0 transition-all duration-300;
            }

            .logo { 
                @apply mx-auto mt-3 mb-4;        

                img {
                    @apply h-10;
                }
            }

            ul {
                @apply w-full border-t;        
            }
    
            li {
                @apply mx-auto py-5 px-4 text-primary-800 border-b;        
            }

            .header-lang {
                @apply py-5 px-4;        
            }
        }

        .menu-panel__content__processing {
            @apply absolute w-full h-full top-0 flex items-center justify-center text-primary-400;
            @apply opacity-0 transition-opacity duration-300;
        }
    }
}
