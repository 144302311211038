﻿.btn {
    @apply px-4 py-2;
    @apply text-sm border rounded transition-colors;
    @apply flex flex-row flex-nowrap content-center items-center gap-x-2;
}

.btn-primary {
    @apply text-white bg-primary-500 border-primary-500;
    @apply hover:bg-primary-600 active:bg-primary-700 disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:bg-gray-400;
}

.btn-outline-primary {
    @apply text-primary-500 bg-white border-primary-200;
    @apply active:text-white active:bg-primary-600;
    @apply hover:text-white hover:bg-primary-400 disabled:hover:bg-white;
    @apply disabled:text-gray-200 disabled:border-gray-200 disabled:hover:text-gray-200 disabled:hover:border-gray-200;
}

.btn-secondary {
    @apply text-white bg-secondary-500 border-secondary-500;
    @apply hover:bg-secondary-600 active:bg-secondary-700 disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:bg-gray-400;
}

.btn-success {
    @apply text-white bg-lime-500 border-lime-500;
    @apply hover:bg-lime-600 active:bg-lime-700 disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:bg-gray-400;
}

.btn-outline-success {
    @apply text-lime-500 bg-white border-2 border-lime-500;
    @apply active:text-white active:bg-lime-600;
    @apply hover:text-white hover:bg-lime-500 disabled:hover:bg-white;
    @apply disabled:text-gray-200 disabled:border-gray-200 disabled:hover:text-gray-200 disabled:hover:border-gray-200;
}

.btn-danger {
    @apply text-white bg-red-600 border-red-600;
    @apply hover:bg-red-700 active:bg-red-800 disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:bg-gray-400;
}

.btn-outline-danger {
    @apply text-red-600 bg-white border-2 border-red-600;
    @apply active:text-white active:bg-red-700;
    @apply hover:text-white hover:bg-red-600 disabled:hover:bg-white;
    @apply disabled:text-gray-200 disabled:border-gray-200 disabled:hover:text-gray-200 disabled:hover:border-gray-200;
}
