﻿form {
    h4 {
        @apply mt-4 ml-3 mb-0 pb-1 text-sm text-primary-500;
    }

    .form-field {
        @apply my-2;
    }

    .form-field {
        input, textarea {
            @apply text-black;

            &[readonly] {
                @apply text-gray-500;
                @apply focus:shadow-none focus:bg-white focus:border-gray-300;

                &:focus-within ~ label {
                    @apply text-gray-700;
                }
            }
        }
    }
}

.form-field {
    @apply relative;
    @apply text-sm;

    input, textarea {
        @apply pt-3 pb-0;
    }

    &.no-label input {
        @apply py-1.5;
    }

    input:hover, textarea:hover {
        ~ label:before {
            @apply h-4 -mt-0.5 bg-gray-50;
        }
    }

    input, textarea {
        @apply block w-full px-3 m-0;
        @apply text-base font-normal;
        @apply text-gray-700 bg-white bg-clip-padding hover:bg-gray-50 hover:read-only:bg-white focus:text-gray-700 focus:read-only:text-gray-700 focus:bg-gray-100 focus:read-only:bg-gray-100;
        @apply border border-0 border-b border-solid border-gray-300 focus:border-primary-400 focus-visible:outline-0 read-only:border-0;
        @apply transition ease-in-out;
        @apply focus:shadow-primary-400;

        &:disabled {
            @apply text-gray-600 bg-gray-50 border-0;

            + label {
                @apply text-gray-400;
            }
        }

        &::placeholder {
            @apply text-transparent;
        }

        &:focus-within ~ label,
        &:not(:placeholder-shown) ~ label {
            @apply leading-3 -translate-y-2;
            font-size: 0.7rem;

            &:before {
                @apply h-4 -mt-0.5;
            }
        }

        &:focus-within ~ label {
            @apply text-primary-400;

            &:before {
                @apply bg-gray-100;
            }
        }

        &.invalid:not(:focus-within) {
            @apply border-red-300;
        }

        &.modified.valid:not(:focus-within) {
            @apply border-green-600;
        }
    }

    > label {
        @apply absolute top-0 left-0 w-full h-full mr-5 px-3 py-2.5;
        @apply text-gray-700 leading-4 text-sm;
        @apply transition-all pointer-events-none;
    }

    textarea ~ label:before {
        content: '';
        width: calc(100% - 1.25rem);
        z-index: -1;
        @apply absolute -mx-2.5 -mt-1 h-6 bg-white transition-colors;
    }

    input[type=radio] ~ label:before {
        content: none;
    }

    i {
        @apply mx-2.5 my-0;
        @apply text-gray-400;
        @apply top-1/2 absolute -translate-y-1/2;
    }

    > i ~ input {
        @apply pl-8;
    }

    > i ~ label {
        @apply ml-5;
    }

    input[type="number"] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            @apply -mt-3 -mr-3;
        }
    }

    &.input-currency--euro {
        input[type="number"] {
            @apply pr-8;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                @apply -mt-3 -mr-8;
            }
        }

        &::after {
            @apply text-lg w-4 h-4 absolute top-2 right-3;
            content: '€';
        }
    }

    input[type="date"] {
        @apply relative;
        height: 2.3125rem;

        &::-webkit-calendar-picker-indicator {
            @apply text-transparent bg-none block w-5 h-5 absolute top-2 right-1 cursor-pointer;
        }

        &:before {
            @apply text-transparent bg-none block w-4 h-4 absolute top-2 right-2 text-gray-700 transition-colors duration-300;
            font-family: 'Font Awesome 6 Free';
            content: '\f133';
        }

        &:focus-within:before {
            @apply text-primary-500;
            content: '\f073';
        }
    }

    .form-field--trailing-button {
        @apply absolute top-1/2 right-1 -translate-y-1/2 text-gray-700 hover:text-primary-400 transition;

        button {
            @apply flex text-sm rounded h-6 w-6 disabled:hidden;
        }

        i {
            @apply static translate-y-0 m-auto;
            color: inherit;
        }
    }
}

.form-field--description {
    @apply mt-0.5 leading-3;
    font-size: 0.7rem;
}

table .form-field input {
    @apply px-0 py-1 text-sm leading-5;
}

.validation-message {
    @apply text-red-600 text-xs leading-4 mt-0.5 mb-1;
}

form .form-field + .validation-message {
    @apply -mt-2;
}

.form-field--radio {
    @apply inline-block relative mb-3;
    @apply text-base leading-6;

    input[type=radio] {
        @apply absolute top-1 left-0 w-8 h-5 z-0 opacity-0;
    }

    input[type=radio] + label {
        @apply block pl-6 cursor-pointer;

        &:before {
            content: '';
            @apply absolute top-1 left-0 w-4 h-4 z-10;
            @apply bg-transparent;
            @apply border-2 rounded-xl border-gray-400 transition-colors;
        }

        &:after {
            content: '';
            @apply absolute top-2 left-1 w-2 h-2 z-20;
            @apply bg-primary-400;
            @apply scale-0 transition-transform;
            border-radius: 50%;
        }
    }

    input[type=radio]:checked + label {
        &:before {
            @apply border-primary-400;
        }

        &:after {
            @apply scale-100;
        }
    }

    input[type=radio]:focus + label {
        &:before {
            @apply shadow-primary-400;
        }
    }
}

[type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type=checkbox]:indeterminate,
.indeterminate[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

[type='checkbox']:checked,
[type='checkbox']:indeterminate,
.indeterminate[type='checkbox'],
[type='radio']:checked {
    @apply bg-transparent bg-center bg-no-repeat;
    background-color: currentColor;
    background-size: 100% 100%;
}

.form-field--checkbox {
    @apply flex items-center;
}

[type='checkbox'] {
    @apply h-5 w-5 float-left align-top;
    @apply border-2 border-gray-400 rounded-sm;
    @apply bg-white bg-center bg-contain bg-no-repeat;
    @apply transition duration-200;
    @apply appearance-none cursor-pointer disabled:cursor-default;
    min-width: 1.25rem;

    &:checked,
    &:indeterminate,
    &.indeterminate {
        @apply bg-secondary-400 border-secondary-400 disabled:bg-gray-400 disabled:border-gray-400;
        background-size: 140% 140%;
    }

    ~ label {
        @apply ml-2 inline-block text-gray-800 text-sm;
    }

    &:not(:disabled) ~ label {
        @apply cursor-pointer disabled:cursor-default;
    }
}

.form-field--file {
    @apply w-full h-16 relative flex place-items-center border shadow hover:bg-gray-50;

    input[type="file"] {
        @apply opacity-0 w-full h-full cursor-pointer;
        z-index: 1;
    }

    label {
        @apply w-full px-4 text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
}
