﻿#cookie-management {
    ul {
        @apply pb-2;
    }

    label {
        @apply mb-1;
    }

    p {
        @apply mb-4;
    }

    .btn {
        @apply inline-block text-base bg-[#0099cc];
        font-weight: 300;
    }
}
